import React,{ Spinner } from 'reactstrap';


// ==============================|| Loader ||============================== //

const Loader = () => (
<div style={{
  zIndex: 2001,
  position: 'absolute',
  left: "50%",
  top: "50%",
 
}}
  >
  <Spinner 
  color='primary'
  />
</div>

);

export default Loader;
